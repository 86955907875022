import { useEffect, useState } from 'react';

interface IStickyObserver {
  (reference: RefObject<HTMLDivElement>, callback?: (x?: string) => void): [boolean];
}

const useStickyObserver: IStickyObserver = (reference, callback): [boolean] => {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const stickyElm = reference.current;
    if (!stickyElm) return;
    const observer = new IntersectionObserver(
      ([e]) => {
        setSticky(e.intersectionRatio < 1);
        if (e.intersectionRatio === 1) {
          callback && callback(stickyElm?.dataset.target);
        }
      },
      { threshold: [1, 0] }
    );
    observer.observe(stickyElm as Element);

    return () => {
      observer.unobserve(stickyElm as Element);
    };
  }, []);

  return [isSticky];
};
export default useStickyObserver;
